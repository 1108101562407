
import { Vue, Component } from 'vue-property-decorator'
import { CalcDetail, UserCostList, QuotaList, SpecialCostList } from '@/types/preBidCalc.d'
import { ElForm } from 'element-ui/types/form'
import { DicInfo } from '@/types/common.d'
import { oneDecimal, twoDecimalNoZero } from '@/utils/validate'

@Component
export default class CalcListAdd extends Vue {
  private info: CalcDetail = {
    projectId: '',
    maintainType: '',
    maintainGrade: '',
    userCostList: [],
    specialCostList: [],
    otherCostList: []
  }

  // 养护类型列表
  private maintainTypeList = []
  // 养护等级列表
  private maintainGradeList = []
  // 费用类别列表
  private costTypeList: Array<DicInfo> = []
  // 表格编辑列
  private editIndex: number | null = null
  // 表格类别 0无 1人工费 2专项费用 3其他事项
  private editType = 0
  // 类别列表
  private libTypeList: Array<DicInfo> = []
  // 养护内容子项
  private libChildTypeList = []
  // 定额库详情
  private libDetail: any = {}
  private dialogFormVisible = false
  // 人工费总额
  private allPersonPrice = 0
  // 专项费总额
  private allSpecialPrice = 0
  // 其他事项费用总额
  private allOtherPrice = 0
  // 成本合计
  private allPrice= 0
  private allPriceShow = false
  private tableData: Array<{type: string; value: number}> = []
  private submitShow = false

  private rules = {
    projectId: [{ required: true, message: '请选择所属项目', trigger: ['blur', 'change'] }],
    maintainType: [{ required: true, message: '请选择养护类型', trigger: ['blur', 'change'] }],
    maintainGrade: [{ required: true, message: '请选择养护等级', trigger: ['blur', 'change'] }],
    userCostList: [{ required: true, message: '请新增人工费', trigger: ['blur', 'change'] }]
  }

  private tableRules = {
    costName: [
      { required: true, message: '请输入费用名称', trigger: ['blur', 'change'] }
    ],
    libraryType: [
      { required: true, message: '请选择类别', trigger: ['blur', 'change'] }
    ],
    libId: [
      { required: true, message: '请选择定额标准', trigger: ['blur', 'change'] }
    ],
    engineeringQuantity: [
      { required: true, message: '请输入工程量', trigger: ['blur', 'change'] },
      { validator: twoDecimalNoZero, trigger: ['blur', 'change'] }
    ],
    maintainNum: [
      { required: true, message: '请输入养护期', trigger: ['blur', 'change'] },
      { validator: oneDecimal, trigger: ['blur', 'change'] }
    ],
    unitPrice: [
      { required: true, message: '请输入单价', trigger: ['blur', 'change'] },
      { validator: twoDecimalNoZero, trigger: ['blur', 'change'] }
    ],
    specialType: [
      { required: true, message: '请选择费用类别', trigger: ['blur', 'change'] }
    ],
    costNames: [
      { required: true, message: '请输入事项名称', trigger: ['blur', 'change'] }
    ],
    totalPrice: [
      { required: true, message: '请输入费用', trigger: ['blur', 'change'] },
      { validator: twoDecimalNoZero, trigger: ['blur', 'change'] }
    ]
  }

  get normalProjectList () {
    return this.$store.state.normalProjectList
  }

  created () {
    this.getMaintainTypeList()
    this.getMaintainGradeList()
    this.getLibTypeList()
    this.getCostTypeList()
  }

  // 养护类型列表
  getMaintainTypeList () {
    this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'maintainType' }).then(res => {
      this.maintainTypeList = res.maintainType || []
    })
  }

  // 养护等级列表
  getMaintainGradeList () {
    this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'maintainGrade' }).then(res => {
      this.maintainGradeList = res.maintainGrade || []
    })
  }

  // 费用类别列表
  getCostTypeList () {
    this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'specialType' }).then(res => {
      this.costTypeList = res.specialType || []
    })
  }

  // 养护类型&养护等级改变清空人工费列表
  clearUserCostList () {
    for (let i = this.info.userCostList.length - 1; i >= 0; i--) {
      if (this.info.userCostList[i].libraryType === '1') {
        if (this.editIndex === i && this.editType === 1) {
          this.info.userCostList[i].libraryType = ''
          this.info.userCostList[i].libId = ''
          this.info.userCostList[i].unitPrice = ''
          this.info.userCostList[i].totalPrice = ''
          this.info.userCostList[i].quotaList = []
          this.$set(this.info.userCostList, i, this.info.userCostList[i])
        } else {
          this.info.userCostList.splice(i, 1)
          this.editIndex && this.editIndex--
        }
      }
    }
  }

  // 人工费新增
  userCostAdd () {
    if (this.editIndex !== null) {
      this.$message.warning('请先保存！')
    } else {
      this.editType = 1
      this.info.userCostList.push({
        costName: '',
        libraryType: '',
        libId: '',
        unitPrice: '',
        engineeringQuantity: '',
        maintainNum: '',
        totalPrice: '',
        remarks: '',
        quotaList: []
      })
      this.editIndex = this.info.userCostList.length - 1
    }
  }

  // 专项费新增
  specialCostAdd () {
    if (this.editIndex !== null) {
      this.$message.warning('请先保存！')
    } else {
      this.editType = 2
      this.info.specialCostList.push({
        specialType: '',
        costName: '',
        unitPrice: '',
        engineeringQuantity: '',
        totalPrice: '',
        remarks: ''
      })
      this.editIndex = this.info.specialCostList.length - 1
    }
  }

  // 其他新增
  otherCostAdd () {
    if (this.editIndex !== null) {
      this.$message.warning('请先保存！')
    } else {
      this.editType = 3
      this.info.otherCostList.push({
        costName: '',
        totalPrice: ''
      })
      this.editIndex = this.info.otherCostList.length - 1
    }
  }

  // 表格保存
  onSave (type: number) {
    const formList = ['', 'personTable', 'specialTable', 'otherTable']
    ;(this.$refs[formList[type]] as ElForm).validate((valid) => {
      if (valid) {
        this.editIndex = null
        this.editType = 0
      }
    })
  }

  // 列表编辑当条
  onEdit (index: number, type: number) {
    if (this.editIndex !== null) {
      this.$message.warning('请先保存！')
    } else {
      this.editType = type
      this.editIndex = index
    }
  }

  // 删除当条
  onDelete (index: number, type: number) {
    if (this.editIndex !== null) {
      this.$message.warning('请先保存！')
    } else {
      this.$confirm('确认删除吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (type === 1) {
          this.info.userCostList.splice(index, 1)
        } else if (type === 2) {
          this.info.specialCostList.splice(index, 1)
        } else if (type === 3) {
          this.info.otherCostList.splice(index, 1)
        }
        this.$message.success('删除成功')
      })
    }
  }

  // 定额详情
  getLibDetail (row: UserCostList) {
    if (row.quotaList) {
      this.libDetail = row.quotaList.find((item: QuotaList) => item.libId === row.libId)
      this.getLibChildTypeList(row.libraryType)
      this.dialogFormVisible = true
    }
  }

  // 其他类别列表
  getLibTypeList () {
    this.$axios.get<DicInfo>(this.$apis.businessData.selectDicByPage, {
      dicType: 'libType'
    }).then((res) => {
      this.libTypeList = res.list || []
    })
  }

  // 定额库字段列表
  getLibChildTypeList (libraryType: string) {
    this.$axios.get(this.$apis.preBidCalc.selectCalculateLibraryFieldByList, {
      libType: libraryType
    }).then((res) => {
      this.libChildTypeList = res.list || []
    })
  }

  // 类别名称
  getLibTypeName (libraryType: string) {
    return this.libTypeList.find(item => item.dicCode === libraryType)?.dicValue
  }

  // 费用类别
  getCostTypeName (specialType: string) {
    return this.costTypeList.find(item => item.dicCode === specialType)?.dicValue
  }

  // 定额标准名称
  getLibName (row: UserCostList) {
    if (row.quotaList) {
      return row.quotaList.find((item: QuotaList) => item.libId === row.libId)?.libName
    }
  }

  // 定额列表
  getQuotaList (row: UserCostList) {
    row.quotaList = []
    row.libId = ''
    row.unitPrice = ''
    const url = row.libraryType === '1' ? this.$apis.preBidCalc.selectCalculateLibraryUserByPage : this.$apis.preBidCalc.selectCalculateLibraryByPage
    const info = row.libraryType === '1' ? {
      maintainType: this.info.maintainType,
      maintainGrade: this.info.maintainGrade
    } : { libType: row.libraryType }
    this.$axios.get(url, { ...info }).then(res => {
      if (row.libraryType === '1') {
        // 人工定额列表
        row.quotaList = res.list || []
      } else {
        // 定额库列表
        row.quotaList = res.library.list || []
      }
    })
  }

  // 计算人工费单价
  countPrice (row: UserCostList) {
    const data = row.quotaList && row.quotaList.find(item => item.libId === row.libId)
    if (data) {
      if (row.libraryType === '1') {
        row.unitPrice = data.costPrice || ''
      } else {
        row.unitPrice = data.totalPrice || ''
      }
    }
  }

  // 计算人工费合价
  getPersonTotalPrice (row: UserCostList) {
    const data = row.quotaList && row.quotaList.find(item => item.libId === row.libId)
    if (row.unitPrice && row.engineeringQuantity && row.maintainNum && data) {
      if (row.libraryType === '1') {
        row.totalPrice = (Number(row.unitPrice) * (Number(row.engineeringQuantity) / Number(data.productPrice)) * (Number(row.maintainNum) / Number(data.maintainNum))).toFixed(2).toString() || ''
      } else {
        row.totalPrice = (Number(row.unitPrice) * Number(row.engineeringQuantity) * (Number(row.maintainNum) / Number(data.maintainNum))).toFixed(2).toString() || ''
      }
    }
    return row.totalPrice
  }

  // 专项费合价
  getSpecialTotalPrice (row: SpecialCostList) {
    if (row.unitPrice && row.engineeringQuantity) {
      row.totalPrice = (Number(row.engineeringQuantity) * Number(row.unitPrice)).toFixed(2).toString() || ''
    }
    return row.totalPrice
  }

  // 整体测算
  onAllCount () {
    (this.$refs.info as ElForm).validate(valid => {
      if (valid) {
        if (this.editIndex !== null) {
          this.$message.warning('请先保存！')
        } else {
          this.tableData = []
          this.allPersonPrice = 0
          this.allSpecialPrice = 0
          this.allOtherPrice = 0
          // 人工
          this.allPersonPrice = Math.round(this.info.userCostList.reduce((prev: any, next: any) => {
            return Number(prev) + Number(next.totalPrice)
          }, 0) * 100) / 100
          this.tableData.push({
            type: '人工费',
            value: this.allPersonPrice
          })
          // 专项
          if (this.info.specialCostList.length > 0) {
            this.allSpecialPrice = Math.round(this.info.specialCostList.reduce((prev: any, next: any) => {
              return Number(prev) + Number(next.totalPrice)
            }, 0) * 100) / 100
            this.info.specialCostList.forEach((item: SpecialCostList) => {
              const types = this.costTypeList.find(items => items.dicCode === item.specialType)
              if (types) {
                this.tableData.push({
                  type: types.dicValue,
                  value: Number(item.totalPrice)
                })
              }
            })
          }
          // 其他
          this.allOtherPrice = Math.round(this.info.otherCostList.reduce((prev: any, next: any) => {
            return Number(prev) + Number(next.totalPrice)
          }, 0) * 100) / 100
          this.tableData.push({
            type: '其他事项费用',
            value: this.allOtherPrice
          })
          // 成本总额
          this.allPrice = this.allPersonPrice + this.allSpecialPrice + this.allOtherPrice
          this.allPriceShow = true
        }
      }
    })
  }

  onSubmit () {
    this.submitShow = true
    this.info.userCostList.forEach(item => {
      delete item.quotaList
    })
    this.$axios.post(this.$apis.preBidCalc.insertCalculateFront, { ...this.info }).then(() => {
      this.$message.success('操作成功')
      this.$router.back()
    }).finally(() => {
      this.submitShow = false
    })
  }
}
